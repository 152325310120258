<template>
  <div class="ganjin-content">
    <div class="bottom-view" v-if="isWxBrowser">
      <img class="qrcode" src="../../assets/images/ganjin/qrcode_for_gh_d89d6b461c06_430.jpg" />
      <div class="qrtext">长按识别官方公众号</div>
      <div class="qrtext-mini">再按照提示扫描此授权码进行查询</div>
    </div>
    <div class="bottom-view" v-else>
      <img class="qrcode-mini" src="../../assets/images/ganjin/saoyisao.png" />
      <div class="qrtext">请使用微信扫一扫</div>
      <div class="qrtext">扫描授权码</div>
      <div class="qrtext-mini mt40">或直接在微信搜索"WDOM"</div>
      <div class="qrtext-mini">关注官方公众号</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QueryResultNew",
  metaInfo: {
    title: 'WDOM'
  },
  data() {
    return {
      isWxBrowser: false,
    }
  },
  created() {
    this.isWechatBrowser()
  },
  methods: {
    isWechatBrowser() {
      // 判断是否在微信中打开
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      const environmental = window.navigator.userAgent.toLowerCase();
      //console.log('判断微信',environmental,environmental.match(/MicroMessenger/i));
      //用  === （建议使用）
      let flag = (environmental.match(/MicroMessenger/i)[0] === 'micromessenger');
      this.isWxBrowser = flag;
    }
  }

}
</script>

<style scoped>
.ganjin-content {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100vh; /* 使容器高度充满整个屏幕 */
}

.bottom-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  position: absolute;
}

.qrcode {
  max-width: 100%; /* 确保图片不会超过父容器 */
  height: auto; /* 保持图片比例 */
  margin-bottom: 20px;
}

.qrcode-mini {
  max-width: 50%; /* 确保图片不会超过父容器 */
  height: auto; /* 保持图片比例 */
  margin-bottom: 20px;
}

.qrtext {
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
}

.qrtext-mini {
  font-weight: 400;
  font-size: 12px;
  margin-top: 10px;
  color: #999999;
  text-align: center;
}

.mt40{
  margin-top: 40px;
}

</style>